import axios from 'axios';

//api Link
//export const API_URL = 'https://api.corpadmin.qa.tvb.com/api' //qa
export const API_URL = 'https://api.corpadmin.tvb.com/api' //prod

//export const USER_URL = 'https://cms.corpadmin.qa.tvb.com' //qa
export const USER_URL = 'https://cms.corpadmin.tvb.com' //prod
//export const USER_URL = 'http://10.2.8.61:8080'

//export const ID_URL = 'http://10.2.8.61:8080'
//export const ID_URL = 'https://cms.corpadmin.qa.tvb.com' //qa
export const ID_URL = 'https://cms.corpadmin.tvb.com' //prod

//const Mytvb_Token = "BLN-YV0pBgg1wchrt5MObI81gz16j8u8vg4Yai2sbUI" //qa
const Mytvb_Token = "5pt5I5-oH-uOYF2u-4ge35mH0fYuJ24mOXllWURVMoA" //prod
//Cloud Testing
const userRequest = axios.create({
  baseURL: USER_URL
});
// ID api
const IDRequest = axios.create({
    baseURL: ID_URL+'/corp_admin',
    headers: { 
        "Mytvb-Client-Token": Mytvb_Token
     },
  });

// admin api
const AdminRequest = axios.create({
    baseURL: API_URL+'/members',
    headers: { 
        "Access-Control-Allow-Origin": "*", 
        "Mytvb-Client-Token": Mytvb_Token,
     }
  });


// User 相關的 api
export const Login = data => userRequest.post('/login', data);
export const Logout = data => userRequest.post('/signOut', data);
export const Signup = data => userRequest.post('/add_user', data);

export const Account_search = data => AdminRequest.post('/query', data);

export const Staff_detail = data => IDRequest.post('/customer/account_enquiry', data);
export const Update_detail = data => IDRequest.post('/customer/update_profile', data);
